import * as React from "react"
import { Link } from "gatsby"
import ContactForm from "../components/Home/Contact/ContactForm"
import Layout from "../components/layout"
import MastheadImage from "../images/qoi-mockup.png"
import Screenshot1 from "../images/qoi-screenshot-1.png"
import Screenshot2 from "../images/qoi-screenshot-2.png"
import OtherWork2 from "../images/actionera-mockup.png"
import OtherWork1 from "../images/mockup-smartpro.png"
import "./page.css"

const WorkQOI = () => {

  return (
    <div>
      <Layout showHeader selectedHeader={`OurWorks`}>
        <section id="header" className="our-works-content min-h-screen flex flex-col">
          <div className="container mx-auto pt-20 pb-10 px-8 lg:px-0 lg:pb-12">
            <h1 className="heading uppercase font-semibold text-4xl md:text-7xl">
              Our Works
            </h1>
          </div>
          <div className="work-preview w-full">
            <div className="mobile-wrap" style={{ backgroundImage: 'url(' + MastheadImage + ')'}}>
              <img src={MastheadImage} alt="Preview of QOI"/>
            </div>
          </div>
          <div className="w-full bg-white">          
            <div className="container mx-auto w-full px-8 py-8 lg:px-0 lg:pt-12 pb-18">
              <div className="block lg:flex lg:justify-between">
                <div className="works-title text-6xl font-bold lg:w-1/2 text-[#707070]">
                QOI Systems Integration
                </div>
                <div>
                  <div class="works-type-gradient inline-block rounded-full p-4 mx-2 text-white">
                    Web Design
                  </div>
                  <div class="works-type-gradient  inline-block rounded-full p-4 mx-2 text-white">
                    Web Development
                  </div>

                </div>

              </div>     
              <div className="work-body py-8">
              A Vancouver-based company with over a decade of experience, <a href="https://qoisystems.com/" target="_blank" rel="noreferrer">QOI Systems Integration</a> offers high-quality smart home system integration at a scale of your choosing. Their customizable packages and many offerings allow them to cater to the unique needs and varying lifestyles of their clients. 
  <br/><br/>
              As QOI provides a wide array of products and services, TechTank ensured that these services are organized and showcased in a clean and easy-to-navigate way. The website allows users to easily visualize what they may avail of by giving glimpses of the various products you can choose from, and by succinctly explaining their uses and benefits.  
              </div>
              <div className="flex justify-center">
                <img src={Screenshot1}  alt="Screenshot of QOI"/>
              </div>
              <div className="work-body py-8">
              Categorized into Home Technology, Home Security and Smart Shades, QOI’s offerings can provide you with everything you need in order to make the most out of your space. Worried about the security? They provide alarm and camera systems, as well as 24-hour monitoring for your home. What about the essentials? They make your home’s systems such as lighting, and heating easy to manage by putting them all under one app. Want to relax in style? They can set up your custom home theater, complete with a home controller which lets you access your screen, receivers, and speakers with just one touch. 
              </div>
              <div className="flex justify-center">
                <img src={Screenshot2}  alt="Screenshot of QOI"/>
              </div>
              <div className="work-body py-8">
              To put it simply, TechTank designed their site in a way that communicates what QOI is all about: quality and innovation.
              </div>
              <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start">



              </div>
            </div>
          </div>
        </section>

        <div className="other-works-container w-full">
          <section id="other-works" className="container mx-auto w-full px-8 py-8 lg:px-0 lg:pt-12 pb-32">
          <h3 className="heading text-5xl py-8">View more works</h3>
            <div className="other-work-cards flex gap-4 content-center">
              <Link to="/work-smartpro" className="other-work px-8 text-center" style={{backgroundImage: 'url(' + OtherWork1 + ')'}}>
                  <span>SMART-PRO CLEANERS</span>
              </Link>
              <Link to="/work-actionera" className="other-work " style={{backgroundImage: 'url(' + OtherWork2 + ')'}}>
                  <span>ACTIONERA</span>
              </Link>
            </div>
          </section>
        </div>

        <ContactForm />
      </Layout>
    </div>
  )
}

export default WorkQOI
